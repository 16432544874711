import Document from "../../components/v2/Document";
import BlogDocument from "../../components/_pages/v2/blog/BlogDocument";

const Blog = () => (
	<Document
		title="Blog - Success Stories"
		ogData={{
			title: "Stay Nimble. Our Latest Success Stories. - Stay Nimble",
			description:
				"Find our success stories that help you find your confidence and inspiration in the new world of work.",
			image: "https://s3.eu-west-2.amazonaws.com/static.staynimble.co.uk/assets/open-graph/preview.jpg",
			url: "/blog/success-stories/",
		}}
	>
		<BlogDocument category="success-stories" pageTitle="Success Stories" />
	</Document>
);

export default Blog;
